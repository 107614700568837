import actions from './actions';
import AuthService from "../services/auth.service";

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess } = actions;


const login = (username, password) => (dispatch) => {
  dispatch(loginBegin());
  return AuthService.login(username, password).then(
    (data) => {
      dispatch(loginSuccess(data))
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.error) ||
        error.message ||
        error.toString();
      dispatch(loginErr(message));

      return Promise.reject(message);
    }
  );
};


const logOut = () => (dispatch) => {
  dispatch(logoutBegin());
  AuthService.logout();
  dispatch(logoutSuccess(null));
};

export { login, logOut };
